import { z } from 'zod';

import { messages } from '@/features/auth/localization';

export const cashWithdrawal = z.object({
  withdrawalMethod: z.string().optional(),
  availableAmount: z.number().min(1, { message: messages.errorInvalidPhoneNumber }),
  amount: z.number().min(1, { message: messages.errorInvalidPhoneNumber }),
  withdrawalRemarks: z.string().min(1, { message: messages.errorInvalidPhoneNumber }).optional(),
});

export const bankWithdrawal = z.object({
  amount: z.number({ required_error: messages.errorAmount }),
});

export const identityDetails = z.object({
  firstName: z.string().min(1, { message: messages.errorFirstName }).regex(/^[0-9A-Za-z ]+$/, { message: messages.errorIncorrectFormatValue }),
  secondName: z.string().optional(),
  lastName: z.string().min(1, { message: messages.errorLastName }).regex(/^[0-9A-Za-z ]+$/, { message: messages.errorIncorrectFormatValue }),
  nickName: z.string().min(1, { message: messages.errorNickname }).regex(/^[0-9A-Za-z- ]+$/, { message: messages.errorIncorrectFormatValue }),
  birthDate: z.string().min(5, { message: messages.errorBirthdate }),
  address: z.string().optional(),
  city: z.string().optional(),
  country: z.string().min(1, { message: messages.errorCountry }),
});

export const bankVoucherForm = z.object({
  bank: z.string().optional(),
  date: z.string().optional(),
  voucher: z.string().optional(),
  value: z.string().optional(),
  observations: z.string().optional(),
  file: z.string().optional(),
});
