'use client';

import React from 'react';

import Image from 'next/image';

import { IVoucher } from '@/features/modals/interfaces';

import { Button } from '@/components/ui/button';

interface Props {
  voucher: IVoucher,
  setCurrentStep: (step:String) => void,
}

const BankDepositResume = ({ voucher, setCurrentStep }:Props) => {
  return (
    <section>
      <div className="flex flex-col">
        <div className="m-2">
          Banco:
          {voucher.bank}
        </div>
        <div className="m-2">
          Fecha del depósito:
          {(voucher.deposit_date).toString().split('T')[0]}
        </div>
        <div className="m-2">Esta transacción se tomará unos minutos: ######</div>
        <div className="m-2">
          Valor: $
          { voucher.value }
        </div>
        <div className="m-2">
          Observaciones:
          {voucher.observations}
        </div>
        <div className="m-2">
          <Image
            className="w-full  rounded-xl h-full xl:h-[150px] xl:w-full max-lg:h-36 max-md:h-full cursor-pointer transition duration-300 ease-in-out "
            src={voucher.document_url}
            alt="Voucher-image"
            width={200}
            height={200}
          />
        </div>
      </div>
      <Button
        onClick={() => {
          setCurrentStep('step3');
        }}
        type="submit"
        className="block w-full rounded-lg bg-sivarbet-primary overflow-hidden flex-row items-center justify-center py-3 px-5 text-center text-sivarbet-border hover:bg-sivarbet-primary-hover"
      >
        <div className="relative text-sm leading-3 font-sans text-sivarbet-border text-center inline-block min-w-16">
          Siguiente
        </div>
      </Button>
    </section>
  );
};

export default BankDepositResume;
