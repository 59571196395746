import React, { useState } from 'react';

import { es } from 'date-fns/locale/es';

import { Calendar } from '@/components/ui/calendar';
import { Input } from '@/components/ui/input';
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover';

interface InputsProps {
  value: string;
  onChange: (e:number | string) => void;
  placeholder: string;
  pastLimit?: string;
  isEditable: boolean;
  limitYear?: Date;
}

export const InputPhone = ({
  value, onChange, placeholder, isEditable,
}: InputsProps) => {
  return (
    <div className="flex items-center bg-[#0A0E1A] rounded-lg h-[48px] w-full focus:bg-[#0A0E1A] focus:border-white">
      <span className="bg-[#0A0E1A] text-white text-sm border-none outline-none rounded-lg mx-2">+504</span>
      <Input
        className="w-full border-none outline-none bg-[#0A0E1A] h-[48px] rounded-lg box-border text-sm text-sivarbet-secondary-textfocus:border-white focus:ring-0"
        type="number"
        placeholder={placeholder}
        value={value}
        defaultValue={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { return onChange(e.target.value); }}
        disabled={!isEditable}
        maxLength={10}
      />
    </div>
  );
};

export const InputBirthdate = ({
  value, onChange, placeholder, isEditable, limitYear, pastLimit,
}: InputsProps) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - (limitYear ? 21 : 0));

  return (
    <div>
      <Popover open={isEditable && isCalendarOpen} onOpenChange={setIsCalendarOpen}>
        <PopoverTrigger asChild>
          <div
            className={`flex items-center bg-[#0A0E1A] h-[48px] rounded-lg px-3 w-full text-sm text-sivarbet-secondary-text ${isEditable ? 'cursor-pointer' : 'cursor-not-allowed'}`}
            onClick={() => { return isEditable && setIsCalendarOpen(!isCalendarOpen); }}
          >
            {value || placeholder}
          </div>
        </PopoverTrigger>
        <PopoverContent className="w-full p-0 rounded border-none shadow-xl" align="start">
          <Calendar
            mode="single"
            locale={es}
            captionLayout="dropdown-buttons"
            fromYear={new Date().getFullYear() - 100}
            toYear={new Date().getFullYear() - (limitYear ? 21 : 0)}
            selected={value ? new Date(value.split('/').reverse().join('/')) : undefined}
            onSelect={(date: Date | undefined) => {
              if (date) {
                const isoDate = date.toLocaleDateString();
                onChange(isoDate);
              }
            }}
            disabled={(date: Date) => { return date > maxDate || date < new Date(pastLimit || '1920-01-01'); }}
            onDayClick={() => { setIsCalendarOpen(false); }}
            className="bg-gray text-sivarbet-text rounded"
            classNames={{
              day: 'text-sivarbet-text bg-transparent hover:bg-transparent focus:bg-transparent hover:rounded focus:rounded',
              day_disabled: 'text-sivarbet-text/50',
              caption_dropdowns: 'flex justify-evenly items-center bg-gray text-sivarbet-text',
              caption: 'bg-gray text-sivarbet-text',
              caption_label: 'hidden ',
              vhidden: 'hidden',
              dropdown_icon: 'hidden',
              dropdown: 'bg-sivarbet-background text-sivarbet-text rounded p-1',
              cell: 'rounded content-center h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-sivarbet-primary/50 [&:has([aria-selected])]:bg-sivarbet-primary first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
            }}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};
